import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Tags from "../components/Tags";
import PrevNextPosts from "../components/PrevNextPosts";
import Seo from "../components/Seo"; // Import the Seo component
import User from "../assets/svg-icons/user.svg";
import RelatedPosts from "../components/RelatedPosts";
import ShareLinks from "../components/ShareLinks";
import AuthorInfoCard from "../components/AuthorInfoCard";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";

const Post = ({ data, location }) => {
  const { post, site } = data;
  const { t } = useTranslation();

  useEffect(() => {
    cambiarTexto();
  });

  function cambiarTexto() {
    const url = window.location.href;

    // Translation logic remains unchanged
    const languages = ['ar', 'fr', 'es', 'de', 'ru', 'tr', 'zh', 'ur', 'pt', 'it', 'id', 'bn', 'nl', 'hi', 'fa', 'ug', 'so', 'sw', 'no', 'da', 'se', 'ha', 'bs', 'th', 'tg', 'tl'];
    for (const lang of languages) {
      if (url.includes(`/${lang}/`)) {
        translateContent(lang);
        return;
      }
    }
    // Default to English or any fallback logic if needed
    translateContent('en');
  }

  function translateContent(lang) {
    const paragraphs = document.querySelectorAll('.post-content p');
    paragraphs.forEach(parrafo => {
      if (typeof t === 'function') {
        parrafo.textContent = t(parrafo.textContent, { lng: lang });
        console.log(parrafo.textContent);
      } else {
        console.error("La función de traducción no está definida.");
      }
    });
  }

  return (
    <Layout>
      <CookieConsent
        location="bottom"
        buttonText={t("Accept Cookies")}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {t("This website uses cookies to enhance the user experience.")}
      </CookieConsent>
      <Helmet>
        {/* Add hrefLang attribute in the head */}
        <link rel="alternate" hrefLang="en" href={`https://www.quranplayermp3.com${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="ar" href={`https://www.quranplayermp3.com/ar${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="fr" href={`https://www.quranplayermp3.com/fr${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="es" href={`https://www.quranplayermp3.com/es${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="de" href={`https://www.quranplayermp3.com/de${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="ru" href={`https://www.quranplayermp3.com/ru${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="tr" href={`https://www.quranplayermp3.com/tr${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="zh" href={`https://www.quranplayermp3.com/zh${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="ur" href={`https://www.quranplayermp3.com/ur${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="pt" href={`https://www.quranplayermp3.com/pt${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="it" href={`https://www.quranplayermp3.com/it${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="id" href={`https://www.quranplayermp3.com/id${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="bn" href={`https://www.quranplayermp3.com/bn${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="nl" href={`https://www.quranplayermp3.com/nl${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="hi" href={`https://www.quranplayermp3.com/hi${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="fa" href={`https://www.quranplayermp3.com/fa${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="ug" href={`https://www.quranplayermp3.com/ug${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="so" href={`https://www.quranplayermp3.com/so${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="sw" href={`https://www.quranplayermp3.com/sw${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="no" href={`https://www.quranplayermp3.com/no${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="da" href={`https://www.quranplayermp3.com/da${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="se" href={`https://www.quranplayermp3.com/se${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="ha" href={`https://www.quranplayermp3.com/ha${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="bs" href={`https://www.quranplayermp3.com/bs${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="th" href={`https://www.quranplayermp3.com/th${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="tg" href={`https://www.quranplayermp3.com/tg${post?.fields?.slug}`} />
        <link rel="alternate" hrefLang="tl" href={`https://www.quranplayermp3.com/tl${post?.fields?.slug}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.quranplayermp3.com${post?.fields?.slug}`} />
      </Helmet>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.seoImage}
        pathname={location.pathname}
      />
      <div className="main">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <article className="single-post">
                <header className="post-header">
                  <h1 className="post-title">
                    <Trans>{post.frontmatter.title}</Trans>
                  </h1>
                  <div className="post-meta flex">
                    <div className="author-list flex">
                      <Link
                        className="author-image"
                        to={`/author${post.frontmatter.author.fields.slug}`}
                        aria-label={post.frontmatter.author.name}
                      >
                        {post.frontmatter.author.profilePicture !== null ? (
                          <GatsbyImage
                            image={getImage(
                              post.frontmatter.author.profilePicture
                            )}
                            alt={post.frontmatter.author.name}
                          />
                        ) : (
                          <User />
                        )}
                      </Link>
                      <Link
                        to={`/author${post.frontmatter.author.fields.slug}`}
                        className="author-name"
                      >
                        <Trans>{post.frontmatter.author.name}</Trans>
                      </Link>
                      &nbsp;
                    </div>
                    {/* Removed date display */}
                  </div>
                </header>
                {post.frontmatter.featuredImage && (
                  <div className="featured-image-wrap">
                    <GatsbyImage
                      image={getImage(post.frontmatter.featuredImage)}
                      alt={post.frontmatter.title}
                    />
                  </div>
                )}
                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{
                    __html: post.html,
                  }}
                />
                <div className="post-footer">
                  <div className="tag-wrap align-center">
                    {post.frontmatter.tags && (
                      <Tags tags={post.frontmatter.tags} />
                    )}
                  </div>
                  <ShareLinks
                    url={location.href}
                    title={post.frontmatter.title}
                  />
                  <AuthorInfoCard author={post.frontmatter.author} />
                </div>
              </article>
              <PrevNextPosts prev={data.prev} next={data.next} />
            </div>
          </div>
        </div>
      </div>
      <RelatedPosts posts={post.related} count={4} />
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query BlogQuery(
    $slug: String!
    $prev: String
    $next: String
    $language: String!
  ) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              transformOptions: { fit: COVER }
            )
          }
        }
        seoImage: featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FIXED, height: 600, width: 1200)
          }
        }
        description
        tags {
          ...TagQueryFragment
        }
        author {
          ...AuthorQueryFragment
        }
        featured
      }
      excerpt(pruneLength: 150)
      related {
        id
        timeToRead
        fields {
          slug
        }
        frontmatter {
          published
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 340
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER }
                aspectRatio: 1.75
              )
            }
          }
        }
      }
    }
    prev: markdownRemark(fields: { slug: { eq: $prev } }) {
      ...PrevNextPostFragment
    }
    next: markdownRemark(fields: { slug: { eq: $next } }) {
      ...PrevNextPostFragment
    }
    site {
      siteMetadata {
        disqusShortName
        siteLanguage
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
