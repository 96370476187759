import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

const RelatedPosts = ({ posts, count }) => {
  // filter only published related posts
  const VisiblePosts = posts
    .filter(post => post.frontmatter.published)
    .slice(0, count)
    
  const { t, i18n } = useTranslation()

  return (
    <>
      {VisiblePosts.length > 0 && (
        <div className="container">
          <div className="related-posts-wrap">
            <h3 className="section-title h5 text-center">
              {t("You might also like")}
            </h3>
            <div className="row">
              {VisiblePosts.map((post, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                  <article className="related-post-card" data-id={post.id}>
                    {post.frontmatter.featuredImage && (
                      <Link hrefLang={i18n.language} to={post.fields.slug} className="post-img-wrap">
                        <GatsbyImage
                          image={getImage(post.frontmatter.featuredImage)}
                          alt={t(post.frontmatter.title)}
                        />
                      </Link>
                    )}
                    <div className="post-info-wrap">
                      <h2 className="h5 post-title">
                        <Link hrefLang={i18n.language} to={post.fields.slug}>
                          {t(post.frontmatter.title)}
                        </Link>
                      </h2>
                      <div className="post-meta">
                        <time
                          className="post-date"
                          dateTime={post.frontmatter.date}
                        >
                          {post.frontmatter.dateFormatted}
                        </time>
                        {/* Removed the read-time display */}
                      </div>
                    </div>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RelatedPosts
