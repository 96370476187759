import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"

const NextPost = ({ post }) => {
  const { t, i18n } = useTranslation()

  return (
    <div className="post next-post">
      <Link hrefLang={i18n.language} to={post.fields.slug} className="flex">
        {post.frontmatter.featuredImage && (
          <div className="featured-image">
            <GatsbyImage
              image={getImage(post.frontmatter.featuredImage)}
              alt={t(post.frontmatter.title)}
            />
          </div>
        )}
        <div className="content-wrap">
          <div className="nav-text">{t('Newer article')}</div>
          <h4 className="title h5">{t(post.frontmatter.title)}</h4>
          <div className="post-meta">
            {/* Removed the read-time display */}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default NextPost
